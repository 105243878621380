import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Firestore, doc, getDoc, setDoc } from '@angular/fire/firestore';
import { ISclobyOAuth, ISclobyTokenData, accessTokenUrl } from '@scbt-lib/index';
import { now } from 'moment';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class SclobyAuthService {
	constructor(
		private afFs: Firestore,
		private http: HttpClient,
		@Inject('DOMAIN') private DOMAIN: string
	) {}

	getClientOAuth(): Observable<ISclobyOAuth> {
		return from(getDoc(doc(this.afFs, `/scloby-auth/client_OAuth`))).pipe(map((snap) => snap.data() as ISclobyOAuth));
	}

	createSclobyToken(
		code: string,
		storeId: string
	): Observable<{
		success?: boolean;
		error?: unknown;
	}> {
		return this.getClientOAuth().pipe(
			// tap(val => console.log(val)),
			switchMap((clientAuth) => {
				const form = new FormData();
				form.append('client_id', clientAuth.client_id);
				form.append('client_secret', clientAuth.client_secret);
				form.append('redirect_uri', `${this.DOMAIN}/scloby/scloby-redirect/${storeId}`);
				form.append('code', code);

				return this.http.post<ISclobyTokenData>(accessTokenUrl, form).pipe(
					switchMap((data) => setDoc(doc(this.afFs, `/scloby-auth/${storeId}`), data)),
					map(() => ({
						success: true,
					})),
					catchError((error) => {
						console.error(error);
						return of({
							success: false,
							error,
						});
					})
				);
			})
		);
	}

	private tokenDb$: Observable<ISclobyTokenData>;
	getTokenFromDb(storeId: string): Observable<ISclobyTokenData> {
		if (!this.tokenDb$) {
			this.tokenDb$ = from(getDoc(doc(this.afFs, `/scloby-auth/${storeId}`))).pipe(
				map((snap) => snap.data() as ISclobyTokenData),
				switchMap((tokenData) => {
					if (!tokenData) {
						return throwError(() => 'error');
					}
					if (tokenData && tokenData.expires && tokenData.expires <= now() / 1000 + 24 * 60 * 60) {
						return this.refreshToken(storeId, tokenData);
					} else {
						return of(tokenData);
					}
				}),
				shareReplay({
					refCount: false,
					bufferSize: 1,
				})
			);
		}
		return this.tokenDb$;
	}

	refreshToken(storeId: string, tokenData: ISclobyTokenData): Observable<ISclobyTokenData> {
		return this.getClientOAuth().pipe(
			switchMap((clientOAuth) => {
				const form = new FormData();
				form.append('grant_type', 'refresh_token');
				form.append('client_id', clientOAuth.client_id);
				form.append('client_secret', clientOAuth.client_secret);
				form.append('refresh_token', tokenData.refresh_token);
				return this.http.post<ISclobyTokenData>(accessTokenUrl, form).pipe(
					catchError((error) => {
						console.error(error);
						return throwError(() => 'error');
					})
				);
			}),
			switchMap((newToken) => {
				return setDoc(doc(this.afFs, `/scloby-auth/${storeId}`), newToken).then(() => newToken);
			})
		);
	}

	/*
È possibile inoltre ottenere il nostro id del negozio (che viene visualizzato
	anche nella schermata di login alla selezione dell'ambiente,
	 nel caso l'utente sia collegato a più di un negozio) usando la chiamata
	 API GET /v2/sessions/me
	 una volta ottenuto il token
 */
}
